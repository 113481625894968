import { map, Observable } from "rxjs";
import { HttpApiService } from "../../http-request/http-api.service";
import { User } from "../../../shared/models/users.model";
import { Injectable } from "@angular/core";
import { response } from "../../../shared/models/create_plan.model";
import { HttpParams } from "@angular/common/http";
import { Plan } from "../../../shared/interfaces/plan.interface";
import { suppliers } from "../../../shared/models/suppliers_company.model";
import { Target } from "../../../shared/interfaces/target.interace";
import { CompanySupplierResult } from "../../../plan/interfaces/company-supplier-result.interface";
import { PlansResponse } from "../../../plan/interfaces/plans-response.interface";

@Injectable({
  providedIn: 'root',
})
export class OrlUserservice extends HttpApiService<User[]> {
  protected override resourcePath = 'plan/';

  public getAllUsers(): Observable<User[]> {
    return this.list('users/getAll') as unknown as Observable<User[]>;
  }
  public getAllUsersByRoleID(roleId:string): Observable<User[]> {
    return this.list(`users/findByRoleId/${roleId}`) as unknown as Observable<User[]>;
  }
  public getUserById(userId: string): Observable<User> {
    return this.find(`users/findById/${userId}`) as Observable<User>;
  }
  public getAllDepartments(): Observable<any[]> {
    return this.list('budgets/leadDepartmentNames') as unknown as Observable<any[]>;
  }
  public getAllFinanceBusinessPartner(): Observable<any[]> {
    return this.list('budgets/financeBusinessPartners') as unknown as Observable<any[]>;
  }
  public getAlltradingManagers(): Observable<any[]> {
    return this.list('budgets/tradingManagers') as unknown as Observable<any[]>;
  }
  public getBudgets(id:string): Observable<any[]> {
    return this.list(`budgets/${id}`) as unknown as Observable<any[]>;
  }
  public getStatus(): Observable<any[]> {
    return this.list('plan-statuses') as unknown as Observable<any[]>;
  }
  public getSuppliers(): Observable<any[]> {
    return this.list('supplier-and-companies') as unknown as Observable<any[]>;
  }
  // public getSuppliersSearch(query?: string, page?: number, pageSize?: number): Observable<any[]> {
  //   let params = new URLSearchParams();

  //   if (query) {
  //     params.set('query', query);
  //   }
  //   if (page !== undefined) {
  //     params.set('page', page.toString());
  //   }
  //   if (pageSize !== undefined) {
  //     params.set('pageSize', pageSize.toString());
  //   }
  //   return this.list(`supplier-and-companies/searchCompanies?${params.toString()}`) as unknown as Observable<any[]>;
  // }

  public getSuppliersByIds(data?: string): Observable<any[]> {
    const jsonData = JSON.stringify(data);
    let params = new HttpParams().set('data', jsonData);
    return this.list(`supplier-and-companies/by-supplier-ids?${params}`) as unknown as Observable<any[]>;
  }

  public getUsersSearch(query?: string, roleId?: string, page?: number, pageSize?: number): Observable<any[]> {
    let params = new HttpParams();

    if (query) {
      params = params.set('query', query);
    }
    if (roleId) {
      params = params.set('roleId', roleId);
    }
    if (page !== undefined) {
      params = params.set('page', page.toString());
    }
    if (pageSize !== undefined) {
      params = params.set('pageSize', pageSize.toString());
    }
    return this.list(`users/searchUsers?${params.toString()}`).pipe(map(response => response['data']));
  }
  public getSupplierCompany(companyId: number): Observable<any[]> {
    return this.find(`supplier-and-companies/${companyId}`).pipe(map(response => response['data']));
  }
  
  public getSupplierid(supplierId: number): Observable<suppliers> {
    return this.find(`supplier-and-companies/supplier/${supplierId}`) as unknown as Observable<suppliers>;
  }
    
  public getSupplierByParentId(supplierId: number): Observable<suppliers> {
    return this.find(`supplier-and-companies/parent/${supplierId}`) as unknown as Observable<suppliers>;
  }

  public getSupplierCompanyContactDetails(supplierId: number): Observable<any[]> {
    return this.find(`supplier-company-contact-details/by-supplier/${supplierId}`).pipe(map(response => response['data']));
  }

  public getContactByCompanyId(companyId: number): Observable<any[]> {
    return this.find(`supplier-company-contact-details/by-company/${companyId}`).pipe(map(response => response['data']));
  }

  public getTargetNames(supplierid: number): Observable<any[]> {
    return this.find(`budgets/targetNames/${supplierid}`) as unknown as Observable<any[]>;
  }
  
  public getBudgetList(supplierid: number): Observable<any[]> {
    return this.find(`budgets/get-budgets-by-supplierId-and-financialYear/${supplierid}`) as unknown as Observable<any[]>;
  }

  public createPlan(data: any): Observable<response> {
    return this.create(data, '/plans') as unknown as Observable<response>;
  }
  public getPlans(): Observable<any[]> {
    return this.find('plans') as unknown as Observable<any[]>;
  }
  public buyersPlanlist(): Observable<PlansResponse> {
    return this.find(`plans/buyers-list`) as unknown as Observable<PlansResponse>;
  }
  public getPlanById(id: number): Observable<Plan> {
    return this.find(`plans/${id}`).pipe(map(response => response['data']));
  }
  public createNotes(data: any): Observable<any> {
    return this.create(data, '/notes') as unknown as Observable<any>;
  }
  public createUser(data: any) {
    return this.create(data, 'users/create') as unknown as Observable<any>;
  }
  public addUserRoles(id: string, data:any): Observable<any> {
    return this.create(data, `users/${id}/addRoles`);
  }
  public updateNotes(data: any, id: number): Observable<any> {
    return this.put(`notes/${id}`, data) as unknown as Observable<any>;
  }
  public updatePlanNotes(data: any, id: number): Observable<any> {
    return this.update(id, data, 'Plans/planNotes/{:id}');
  }
  public createGrowthOverrider(data: any, id: number): Observable<any> {
    return this.create(data, `variable-agreement-overrider/${id}/overrider`);
  }
  public updateGrowthOverrider(data: any, id: number): Observable<any> {
    return this.put(`variable-agreement-overrider/${id}`, data);
  }
  public updatePlan(data: any, id: number): Observable<any> {
    return this.put(`plans/${id}`, data);
  }
  public getGrowthOverrider(id: number): Observable<any> {
    return this.find(`variable-agreement-overrider/${id}/overrider`);
  }
  public createPlanVersion(data: any): Observable<any> {
    return this.create( data, 'plan-versions');
  }
  public putGrowthOverrider(id: number): Observable<any> {
    return this.find(`variable-agreement-overrider/overrider/${id}`);
  }
  public getMediaMetrics(): Observable<any> {
    return this.list('/variable-agreement-media-metrics');
  }
  public getAllGrowthOverrider(): Observable<any> {
    return this.list('variable-agreement-overrider');
  }
  public deleteGrowthOverrider(variableAgreementId: number): Observable<any> {
    return this.delete(`variable-agreement-overrider/${variableAgreementId}`);
  }
  public getdepartmentList(): Observable<any> {
    return this.list('commercial-hierarchies/departments-pods-subpods');
  }
  public login(id: string): Observable<any> {
    return this.update(id, null, `users/{:id}/lastLogin`);
  }
  findAllUsers(userType: string): Observable<any> {
    return this.list(`users/findAll?usertype=${userType}`);
  }
  public getFixedElement(id:number):Observable<any> {
    return this.list(`/plan-elements/plan/${id}`);
  }
  public getVersionsbyPlanId(id:number):Observable<any>{
    return this.list(`/plan-versions/${id}/published-elements`);
  }

  public getBudgetTargetsByCompany(companyId: number): Observable<Target[]> {
    return this.find(`budgets/targetNames/by-company/${companyId}`) as unknown as Observable<Target[]>;
  }

  public getElementsByPlanIdAndPlanVersionId(planVersionId,planId){
    return this.list(`/plan-versions/${planVersionId}/${planId}/elements`);
  }
  createModelToolResults(query:any):Observable<any>{

    return this.create(query,`/modelling-tool-results/execute-query`)
  }

  public searchCompanies(query?: string, page?: number, pageSize?: number): Observable<any[]> {
    let params = new URLSearchParams();

    if (query) {
      params.set('query', query);
    }
    if (page !== undefined) {
      params.set('page', page.toString());
    }
    if (pageSize !== undefined) {
      params.set('pageSize', pageSize.toString());
    }
    return this.list(`supplier-and-companies/searchCompanies?${params.toString()}`) as unknown as Observable<any[]>;
  }

  public searchSuppliers(query?: string, page?: number, pageSize?: number): Observable<any[]> {
    let params = new URLSearchParams();

    if (query) {
      params.set('query', query);
    }
    if (page !== undefined) {
      params.set('page', page.toString());
    }
    if (pageSize !== undefined) {
      params.set('pageSize', pageSize.toString());
    }
    return this.list(`supplier-and-companies/searchSuppliers?${params.toString()}`) as unknown as Observable<any[]>;
  }

  public getSuppliersByCompany(companyId: number): Observable<CompanySupplierResult> {
    return this.find(`supplier-and-companies/suppliers/by-company/${companyId}`) as unknown as Observable<CompanySupplierResult>;
  }



/**
 * Get a Target for a supplier  by companyId due to business classification.
 * @param companyId - The company Id for the supplier.
 * @returns Observable<Target[]>
 */
  public getBudgetTargetsForSupplier(companyId: number): Observable<Target[]> {
    return this.find(`budgets/targetNames/by-supplier/${companyId}`) as unknown as Observable<Target[]>;
  }
  public growthOverrider(data: any, id: number): Observable<any> {
    return this.create(data, `variable-agreement-overrider/${id}/overrider`);
  }



  public getMediaMetricsId(id: number): Observable<any> {
    return this.list(`/variable-agreement-media-metrics/${id}`);
  }



}
